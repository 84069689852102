import {mainLang} from './skills';


export const mainLanguages = [
    {
        id: mainLang.js.id,
        src: "images/experience/js-logo.svg",
        alt: "programming language logo for JavaScript"
    },
    {
        id: mainLang.py.id,
        src: "images/experience/python-logo.svg",
        alt: "programming language logo for Python"
    },
    {
        id: mainLang.swift.id,
        src: "images/experience/swift.svg",
        alt: "programming language logo for Swift"
    },
    {
        id: mainLang.go.id,
        src: "images/experience/go-logo.webp",
        alt: "programming language logo for Golang"
    },
    {
        id: mainLang.ts.id,
        src: "images/experience/typescript_logo.svg",
        alt: "programming language logo for TypeScript"
    },
    {
        id: mainLang.java.id,
        src: "images/experience/java-logo.webp",
        alt: "programming language logo for Java"
    },
];