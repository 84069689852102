import React from 'react';

const Footer = () => (
    <footer className="container">

        <div className="col">
            <a href="https://github.com/LWRGitHub">GitHub</a>
            <a href="https://www.linkedin.com/in/logan-reynolds/">LinkedIn</a>
        </div>

        <br />
        <p>Logan Reynolds &copy; 2024</p>
        
    </footer>
);

export default Footer;