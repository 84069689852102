export const education = [
    {
        title: `Dominican University Of California  |  General Studies for BS of CS`,
        img: {
            src: 'images/education/dominican-university-of-california-building.webp',
            alt: 'Dominican University Of California logo overlaying front of the Dominican University Of California building.'
        },
    },
    {
        title: "City College of San Francisco  |  AS of CNIT",
        img: {
            src: 'images/education/ccsf-mission.webp',
            alt: 'City College of San Francisco also known as CCSF. Located in San Francisco California. image of ccsf mission collage campus.'
        },
    },
];
