export const ProProj = [
    {
        img: {
            src: "images/experience/stealthStartup.webp",
            alt: "LinkedIn Stealth Startup logo. A black background with a white up arrow in the middle of the image."
        },
        title: 'Stealth Startup: Software Engineer L5',
        projectLink: false,
        codeLink: false
    },
    {
        img: {
            src: "images/experience/adobe.webp",
            alt: "red outline of the letter A and the word Adobe"
        },
        title: 'Adobe: Web Appications Engineer 3',
        projectLink: "https://business.adobe.com/products/analytics/adobe-analytics.html",
        codeLink: false
    },
    {
        img: {
            src: "images/experience/verizon.webp",
            alt: "Words that say Verzon with a black background and red check mark"
        },
        title: 'Verizon: Support Engineer',
        projectLink: "https://www.verizon.com",
        codeLink: false
    },
    {
        img: {
            src: "images/experience/Wipro_Primary Logo_Color_RGB.svg",
            alt: "Screen Shot of the Wipro.com home page."
        },
        title: 'Wipro: App-Cloud Services-Developer',
        projectLink: "https://www.wipro.com/",
        codeLink: false
    },
    {
        img: {
            src: "images/experience/dance4healing.svg",
            alt: "screen shot of www.dance4healing.com this is an image of the dance for healing website mobile site or small version has many people in the image dancing woman has dance for healing logo. a slide show would move if you on the site but this is a still image."
        },
        title: 'Dance4Healing: Full-Stack Software Engineer',
        projectLink: "https://dance4healing.com",
        codeLink: false
    },
    {
        img: {
            src: "images/experience/stage-iv.svg", 
            alt: "This is a screenshot of the homepage for stage four .org. Stage IV.org is tan e-commerce website."
        },
        title: 'StageIV: Software Engineer',
        projectLink: "https://stageiv.org",
        codeLink: false
    },
    {
        img: {
            src: "images/experience/freelancer.webp", 
            alt: "very basic computer fraphic image of a guy wearing a blue Hoodie in front of a laptop black background"
        },
        title: 'Freelancer: Full-Stack Software Engineer',
        projectLink: "https://stageiv.org",
        codeLink: false
    }
];


export const PersonalProj = [
    {
        img: {
            src: "images/experience/tech-career-time-browser-view.webp",
            alt: "Tech Career Time home page"
        },
        title: "TechCareerTime.com",
        projectLink: 'https://www.techcareertime.com',
        codeLink: "https://github.com/LWRGitHub/getInTech"
    },
    {
        img: {
            src: "images/experience/job-search-scraping-img.webp", 
            alt: "Job search app in action."
        },
        title: "Auto Job Search",
        projectLink: 'https://github.com/LWRGitHub/job-search-scraping-linkedin',
        codeLink: "https://github.com/LWRGitHub/job-search-scraping-linkedin"
    },
    {
        img: {
            src: "images/experience/analytics-expert.webp", 
            alt: "Logo for ChatGPT analytics expert"
        },
        title: "Customized version of ChatGPT | Analytics Expert",
        projectLink: 'https://chat.openai.com/g/g-xKyZbu82T-analytics-expert',
        codeLink: "https://chat.openai.com/g/g-xKyZbu82T-analytics-expert"
    },
    {
        img: {
            src: "images/experience/vegan-query-browser-view-zoomed-in.webp",  
            alt: "Screenshot of the home page. The image has photos of the Different vegan dishes with their names such as tomato soup & slaw with coriander."
        },
        title: "Vegan Query",
        projectLink: 'https://github.com/VeganQuery/VeganQuery.github.io',
        codeLink: "https://github.com/VeganQuery/VeganQuery.github.io"
    },
    {
        img: {
            src: "images/experience/kdp-books-site.webp", 
            alt: "this is a screen shot of the home page for The Midnight Dream Publishing website."
        },
        title: "Amazon KDP E-commerce & Website",
        projectLink: 'https://lwrgithub.github.io/book-site/#/',
        codeLink: "https://github.com/LWRGitHub/book-site"
    },
    {
        img: {
            src: "images/experience/womens-basketball.webp", 
            alt: "this is a screen shot of the home page for women's basketball through the years. Image of a big photo black-and-white of women playing basketball with the chalkboard in the back."
        },
        title: "Women's Basketball Through The Years",
        projectLink: 'https://lwrgithub.github.io/womens-basketball-website/#/',
        codeLink: "https://github.com/LWRGitHub/womens-basketball-website"
    },
    {
        img: {
            src: "images/experience/scraping-craigslist.webp", 
            alt: "Image of the app having found something & starting to text a number."
        },
        title: "Scraping Craigslist & TrashNothing.com!",
        projectLink: 'https://github.com/LWRGitHub/cs_scraper',
        codeLink: "https://github.com/LWRGitHub/cs_scraper"
    },
    {
        img: {
            src: 'images/experience/lovers-art.webp', 
            alt: 'This is a screen shot of lovers art websites home page.'
        },
        title: "Art Site Template",
        projectLink: 'https://github.com/LWRGitHub/lover-artist-site', 
        codeLink: 'https://github.com/LWRGitHub/lover-artist-site' 
    },
    {
        img: {
            src: "images/experience/duro-ios.webp", 
            alt: "Image of the app's wallet page."
        },
        title: "Duro iOS App!",
        projectLink: 'https://github.com/LWRGitHub/duro-ios',
        codeLink: "https://github.com/LWRGitHub/duro-ios"
    },
    {
        img: {
            src: "images/experience/astrology-api.webp", 
            alt: "Screenshot of the astrology home page. It has images & names of all the zodiac signs."
        },
        title: "Daily Horoscopes",
        codeLink: "https://github.com/LWRGitHub/astrology-api"
    },
];

