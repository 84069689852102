export const mainLang = {
    js: {
        id:'JavaScript',
    },
    py: {
        id:'Python',
    },
    swift: {
        id:'Swift',
    },
    go: {
        id:'Go',
    },
    ts: {
        id:'TypeScript',
    },
    java: {
        id:'Java',
    },
}
