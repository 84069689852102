import React from 'react';
import Header from './Header';

const Home = () => (
    <div className="m- p-0">

        <Header page={'Software Engineer'}
                focus={'JavaScript, Python, Swift, Java, Go'} 
        />
        
    </div>
);

export default Home;